var chart = null;

$(document).ready(function() {
    load_chart(null);

    $(document).on('click', "#bourse-section .list-table.active .line:not(.active)", function() {
        chart = null;

        $("#bourse-section .list-table.active .line.active").removeClass('active');
        $(this).addClass('active');

        //$('#filters-period .filter-period.active').removeClass('active');
        $('#filters-period .filter-period').eq(4).click();

        //$('#chart').html('');
        //$('#chart').removeClass('loading');
        //load_chart(null);
    });


    $('.load-more-bourse').on('click', function() {
        var t = $(this);

        if (!t.hasClass('loading')) {
            var page = t.data('page');
            var type = $("#bourse-section .list-table.active").data('type');

            $.ajax({
                url: '/load-bourse',
                data: {params: {page: page, type: type}},
                type: 'GET',
                beforeSend: function () {
                    t.addClass('loading');
                },
                success: function (data) {
                    t.removeClass('loading');
                    t.before(data);

                    if (data.length == 0) {
                        t.remove();
                    } else {
                        t.data('page', parseInt(page + 1));
                    }
                }
            });
        }

        return false;
    });

    $('#filters-period .filter-period').on('click', function() {
        var type = $("#bourse-section .list-table.active").data('type');
        var symbol = $("#bourse-section .list-table.active .line.active").data('symbol');
        var type_value = $(this).data('type_value');

        $('#filters-period .filter-period.active').removeClass('active');
        $(this).addClass('active');

        $.ajax({
            url: '/load-bourse-chart',
            data: {params: {type: type, type_value: type_value, symbol: symbol}},
            type: 'GET',
            beforeSend: function () {
                $('#chart').addClass('loading');
            },
            success: function (data) {
                $('#chart').html('');
                load_chart(data);
                $('#chart').removeClass('loading');
            }
        });

        return false;
    });

    $('#filters-type .filter-type').on('click', function() {
        var type = $(this).data('type');

        $('#filters-type .filter-type.active').removeClass('active');
        $(this).addClass('active');

        $('#filters-period .filter-period.active').removeClass('active');
        $('#filters-period .filter-period').eq(4).addClass('active');

        if ($('#bourse-section .list-table[data-type="'+type+'"] .line').length > 0) {

            $('#bourse-section .list-table.active').removeClass('loading').removeClass('active');
            $('#bourse-section .list-table[data-type="' + type + '"]').addClass('active');
            $('#chart').html('');
            load_chart(null);
            $('#chart').removeClass('loading');
        } else {

            $.ajax({
                url: '/load-bourse',
                data: {params: {type: type, page: "1"}},
                type: 'GET',
                beforeSend: function () {
                    $('#chart').addClass('loading');
                    $('#bourse-section .list-table.active').addClass('loading');
                },
                success: function (data) {
                    $('#bourse-section .list-table.active').removeClass('loading').removeClass('active');
                    $('#bourse-section .list-table[data-type="' + type + '"]').prepend(data);
                    $('#bourse-section .list-table[data-type="' + type + '"]').addClass('active');
                    $('#chart').html('');
                    load_chart(null);
                    $('#chart').removeClass('loading');
                }
            });
        }

        return false;
    });

});

function load_chart(values) {
    if (!values) {
        chartData = JSON.parse($('.list-table.active .line.active > .chart-data').html());
    } else {
        chartData = JSON.parse(values);
    }

    language = 'fr';

    chart = AmCharts.makeChart("chart", {
        "theme": "dark",
        "type": "serial",
        "language": language,
        "autoMarginOffset": 10,
        "marginTop": 20,
        "marginRight": 0,
        "dataProvider": chartData,
        "addClassNames": true,
        "dataDateFormat": "YYYYMMDDHHNN",
        "graphs": [{
            "useNegativeColorIfDown": true,
            "balloonText": "Indice: [[value]]</b>",
            "bullet": "round",
            "bulletBorderAlpha": 1,
            "bulletBorderColor": "#FFFFFF",
            "hideBulletsCount": 50,
            "lineThickness": 2,
            "fillAlphas": 1,
            "lineColor": "#260e5b",
            "negativeLineColor": "#260e5b",
            "valueField": "value"
        }],
        "chartScrollbar": {
            "enabled": false
        },
        "chartCursor": {
            "valueLineEnabled": false,
            "valueLineBalloonEnabled": true,
            "categoryBalloonDateFormat": "YYYY MMM DD JJ:NN",
            "enabled": true
        },
        "categoryField": "d",
        "categoryAxis": {
            "parseDates": true,
            "minPeriod": "mm",
            "color": "#000000"
        },
        "valueAxes": [{
            "color": "#000000",
            "axisColor": "#000000"
        }]
    });
}